body {
  overflow-x: hidden;
  margin: 0% !important;
  padding: 0% !important;
  font-family: "Assistant", sans-serif;
}
body::-webkit-scrollbar {
  display: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.black_clr {
  color: #000;
}

.purple_clr {
  color: #612cbd;
}

.bg_purple_clr {
  background-color: white;
}

.input-group-append span {
  height: 100%;
  background: #642fbe;
}

.accountant h2 {
  margin-bottom: 23px;
  color: #000;
  font-weight: 700;
  font-size: 70px;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li:nth-child(2) button {
  background: #7487e6;
  color: #fff;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li button {
  border-color: #7487e6;
}

ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul {
  display: flex;
  justify-content: flex-end;
}

.change_password {
  position: relative;
}

.change_password i {
  transform: translate(0px, -50%);
  top: 50%;
  position: absolute;
  right: 10px;
}

.sweet-alert p {
  font-size: 15px;
  font-weight: 600;
}

.chat-header {
  background-color: #7487e6 !important;
}

.sweet-alert div {
  margin: 0px !important;
  padding: 0px !important;
}

.sweet-alert {
  padding: 28px 52px !important;
}

.accountant {
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

.signup_login_section {
  height: 100vh;
  margin: 0 auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
}

.password_info span {
  font-weight: 700;
}

.bg_info_cls:hover {
  background: #612cbd;
}

.signup {
  position: relative;
  background-color: #fff;
}

.login_form input,
.form-control,
.login_form select {
  color: #000;
  /* background: transparent !important; */
  border: 0px;
  padding: 6px 10px;
}

.disable_input {
  /* background: #727174 !important;
  color: white !important; */
  padding: 10px 10px !important;
  border-radius: 5px;
}

.form-control {
  color: #000 !important;
  background-color: #e5e3e8 !important;
}

input::placeholder,
.form-control,
.login_form select {
  margin-bottom: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #a9a9a9;
}

@media (min-width: 768px) {
  li.side-nav-item.menuitem-active {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-weight: 700;
    color: #fff;
    /* width: 230px; */
    /* background: #e7e0e0 !important; */
    background-color: #7487e6 !important;
    border-top-right-radius: 30px;
    /* Adjust the value as needed */
    border-top-right-radius: 30px;
    /* Adjust the value as needed */
    border-bottom-right-radius: 30px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 769px) {
  li.side-nav-item.menuitem-active {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    font-weight: 700;
    /* background: #e7e0e0 !important; */
    background-color: #632ebe !important;
  }

  .add_width_mobile_view {
    width: 25%;
    /* padding: -2px; */
    color: #7e7e7e;
    font-size: small;
    margin-right: 2px;
    padding: 5px;
  }

  .add_width_mobile_view a.side-nav-link-ref.side-sub-nav-link.side-nav-link {
    padding: 5px;
  }

  .side-sub-nav-link i {
    margin: 0 !important;
  }

  .mobile_logo {
    display: block !important;
  }

  .right_img {
    width: 171px !important;
    height: 160px !important;
    margin-top: 0rem !important;
  }

  .add_head_font_text_para {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: 400 !important;
  }

  .heading_Dashboard {
    font-size: 12px !important;
  }

  .add_padding_child .modal-content {
    padding: 1rem !important;
  }

  .add_font_tab {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 14px !important;
  }
}

body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  /* color: white !important; */
}

.side-nav .menuitem-active a span {
  color: white !important;
}

.login_form select {
  border-radius: 5px;
}

.logged_in .form-check-input[type="checkbox"] {
  border: 1px solid black !important;
  padding: 0px;
  border-radius: 0px;
}

.logged_info p {
  font-size: 13px;
}

.logged_in {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.forgot_password p {
  color: #612bbd;
  /* font-family: Assistant; */
  font-size: 1.12rem;
  font-weight: 600;
  line-height: 1.7rem;
  letter-spacing: 0em;
}

.logged_info .form-check-input {
  width: 0.8em;
  height: 0.8em;
  vertical-align: initial;
}

.btn_info_cls {
  line-height: 14px;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  background: #7487e6;
  color: #fff;
  border: 2px solid #7487e6;
  border-radius: 5px;
}
.btn_info_cls_close {
  line-height: 14px;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  background: #53636a;
  color: #fff;
  border: 2px solid #53636a;
  border-radius: 5px;
}

.btn_info_cls_inactive {
  line-height: 14px;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  color: black;
  border: 2px solid #7487e6;
  border-radius: 5px;
}

button.btn_info_cls:hover {
  color: #7487e6;
  background: transparent;
  border: 2px solid #7487e6;
}

.btn-primary {
  background: #7487e6 !important;
  color: #fff !important;
  border: 2px solid #7487e6 !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background: #7487e6 !important;
  color: #fff !important;
  border: 2px solid #7487e6 !important;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff !important;
  background-color: #8597f1 !important;
  border-color: #8597f1 !important;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff !important;
  background-color: #8597f1 !important;
  border-color: #8597f1 !important;
}

.otp_info {
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.otp_info input {
  font-weight: 600;
  color: #000;
  background: transparent !important;
  border: 1px solid #612bbd;
  font-size: 20px;
  padding: 6px 11px;
  width: 40px;
}

.sinup_cls p {
  margin-left: 0px;
}

.forgot {
  margin-top: 18px;
  color: #ffd523;
}

p.accunt_info_cls {
  font-size: 13px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.signup_right {
  position: relative;
  text-align: center;
  background-image: linear-gradient(179deg, #612cbd, #ac7cea);
}

.signup_right img {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
}

h5.back_btn a {
  color: #000 !important;
}

.enter_password {
  column-gap: 14px;
  display: flex;
  align-items: center;
}

.password_info {
  border: 1px solid #612bbd;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.password_info_1 {
  border-bottom: 1px solid #612cbd;
  width: 21px;
}

h2.title_info {
  color: #000;
  font-weight: 700;
  font-size: 35px;
}

.name_detail {
  display: flex;
  column-gap: 15px;
}

.account_heading img {
  width: 180px;
}

h3.verify_email {
  color: #000;
}

p.digit {
  color: #000;
  font-size: 14px;
  text-align: center;
}

.otp_infoo span {
  flex: 0 0 100%;
  text-align: center;
}

p.inf0_ottp {
  margin-left: 10px;
  position: relative;
  margin-right: 10px;
}

p.inf0_ottp::after {
  position: absolute;
  /* margin-left: 10px; */
  content: "";
  width: 18px;
  height: 3px;
  background: #612cbd;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.welcome_info {
  text-align: center;
  margin: 0 auto;
}

.welcome_page {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.welcome_page h2 {
  font-weight: 400;
  color: #f9f4ff !important;
}

.welcome_page_info {
  /* height: calc(100vh - 64px); */
  height: 80vh;
  /* background-color: #fff; */
}

.welcome_info {
  color: #fff;
  border-radius: 25px;
  /* padding: 100px.; */
  background-image: linear-gradient(183deg, #612cbd, #ac7cea);
  border: 1px solid white;
  /* position: absolute; */
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%,-50%); */
}

.table_responsive {
  overflow-x: auto;
  width: 100%;
}

button.starttour_btn {
  transition: all 0.3s linear;
  /* transform: translate(10px, 10px); */
  font-size: 16px;
  font-weight: 700;
  background: transparent;
  border: 1.5px solid #fff;
  /* margin-right: 23px; */
  /* padding: 8px 46px; */
  /* border-radius: 10px; */
}

button.starttour_btn:hover {
  border: 1.5px solid #fff;
  color: #622dbd;
  background: #fff;
}

.dropdown_custom {
  height: 11.8rem;
  overflow-y: scroll;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  transform: translate(0px, 10px);
  z-index: 999999;
  background-color: white;
}

.add_hover_drop:hover {
  background-color: #e5e3e8;
  box-shadow: none;
}

button.starttour_btn:active {
  border: 1.5px solid #fff;
  color: #622dbd;
  background: #fff;
  box-shadow: none;
}

.heading_info {
  margin: 20px auto 0px auto;
}

.logged_info {
  display: flex;
  column-gap: 7px;
}

.otp_infoo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.otp_infoo input {
  border-radius: 4px;
  background: transparent;
  height: 40px;
  width: 40px !important;
  padding: 0px;
  font-size: 20px;
}

.otp_infoo div {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 11px;
}

.otp_infoo input {
  border: 1px solid #612bbd;
}

.otp_infoo div:focus {
  outline: none;
  border: 0px;
}

.login_form span.text-danger {
  font-size: 13px;
}

.back_btn img {
  width: 5px;
}

.btn_loader .spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  border: 0.2em solid currentColor !important;
}

.spinner-border_form {
  /* border: solid!important; */
  display: flex !important;
  justify-content: center !important;
  height: 90vh !important;
  align-items: center !important;
}

.spinner-border_form1 {
  /* border: solid!important; */
  display: flex !important;
  justify-content: center !important;
  height: 67vh !important;
  align-items: center !important;
}

.spinner-border_Verify_form {
  display: flex !important;
  justify-content: center !important;
  height: 100vh !important;
  align-items: center !important;
}

.bg_info_cls {
  background: #612cbd;
}

.profile_info {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  flex: 0 0 25%;
}

.profile_info ul {
  list-style: none;
  padding-left: 0px;
}

.tab_info {
  background: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 12px;
  flex: 0 0 93%;
}

.tab_info form {
  /* display: flex; */
  column-gap: 20px;
}

.tab_info .nav-tab {
  padding-bottom: 0px !important;
  color: #8c8c8c !important;
  background: #f3f3f3;
}

.tab_info .nav-link.active {
  font-weight: 600;
  color: #000;
  background: transparent;
  border-bottom: 2px solid #612cbd;
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
}

.profile_data {
  flex: 0 0 48%;
}

.content-page {
  background-color: #fff;
}

.tab_info .nav-tabs {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #f3f3f3;
}

.logo span.logo-lg {
  background-color: #faf8ff;
}

/* 
.logo span.logo-lg {
  background-color: #612cbd;
} */

.side-nav {
  background: #faf8ff;
}

.tab_info .nav-link {
  color: #a7a7a7;
  font-weight: 600;
}

.simplebar-content {
  background: #612cbc;
}

.leftside-menu {
  background: #faf8ff !important;
}

.topbarButtons {
  background-color: #632ebe !important;
  color: white;
}

.searchBtn {
  border-radius: 10px;
}

.guZdik {
  max-width: 100% !important;
  height: 140px !important;
}

.border_browser {
  color: gray !important;
  border: 1px solid gray !important;
  background: transparent !important;
}

.parentCreateOrganisation {
  border: 1px solid black;
  box-shadow: 1px 5px 7px 1px;
}

.dragDropFile {
  border: 1px dotted black !important;
  height: 140px;
}

.content-page {
  background-color: #fff;
}

.tab_info .nav-tabs {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  background: #f3f3f3;
}

/* .logo span.logo-lg {
  background-color: #632ebe;
} */
/* 
.logo span.logo-lg {
  background-color: #612cbd;
} */

/*  */

.tab_info .nav-link {
  color: #a7a7a7;
  font-weight: 600;
}

.simplebar-content {
  background: #faf8ff;
}

/* .leftside-menu {
  background: #612cbd !important;
} */

.cursorMain {
  cursor: pointer;
}

.heading_subUser {
  color: #632ebe;
  /* font-size: 23px; */
}

h3.main_title {
  color: #000;
  font-size: 24px;
}

.page-title-right {
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
}

.page-title-right form {
  order: 2;
}

h3.usr_name {
  font-weight: 600;
  color: #642fbe;
  font-size: 20px;
}

.labelSubUser {
  cursor: pointer;
}

.bg_clr {
  background-color: #f3f3f3;
}

.bg_cls_info {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.sweet-alert {
  width: auto !important;
  font-size: 8px;
  font-weight: 500;
  padding: 54px 40px !important;
  border-radius: 10px !important;
}

/* .sweet-alert a,
.sweet-alert a:hover {
  background: #632ebe !important;
} */

span.account-user-name,
span.account-position {
  color: #000;
}

.profile_info img {
  width: 100%;
}

.passwordLabel {
  font-size: 12px;
}

.modal-content button.bg_info_cls {
  font-size: 12px;
  background: #612cbd !important;
  line-height: 0px;
  padding: 12px 11px;
}

.modal-body {
  padding-top: 0px;
}

.modal-content {
  border-radius: 10px !important;
}

button.btn-close {
  color: #612cbd !important;
  border-radius: 100%;
  font-size: 10px;
  margin-bottom: 2px !important;
  font-weight: 800 !important;
}

.modal-title {
  width: 100%;
  text-align: center;
  margin: 0px;
}

table tr {
  text-align: center;
}

button#nav-profile-tab {
  border-top: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 0px !important; */
  border-left: 0px !important;
  /* border: 0px !important; */
}

button#nav-home-tab {
  border-top: 0px !important;
  border-right: 0px !important;
  /* border-bottom: 0px !important; */
  border-left: 0px !important;
  /* border: 0px !important; */
}

.profile_data .form-control:disabled,
.form-control[readonly] {
  color: #6b6b6b !important;
}

/* //model view css */
/* .modal-content,
.imageModelView {
  border-radius: 16px !important;
}

.modal-header,
.paddingNone {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  justify-content: center !important;
}

.modal-title,
.text_font {
  font-size: medium;
  font-weight: 600;
}
.profile_data .form-control:disabled, .form-control[readonly] {
  color: #867f7f !important;
 
} */
/* //model view css */

/* =========================================== */
/* .button {
  cursor: pointer;
  border-radius: 2px;
  outline: none;
  border: none;
  position: relative;
  padding: 8px 16px;
  background: orange;
} */

/* .button:active {
  background: orange;
} */

/* .button__text {
  transition: all 0.3s linear;
  font-size: 20px "";
} */
/* // north api  */
.north_api_comapnyLoader {
  display: flex;
  justify-content: center;
  height: 179px;
  align-items: center;
}

.north_api_comapnyLoader .spinner-border {
  width: 1rem !important;
  height: 1rem !important;
  /* border: 0.2em solid currentColor !important; */
}

.form-control,
.PhoneInputInput {
  /* color: #000; */
  background: #e5e3e8;
  border: 0px;
  padding: 6px 10px;
  border: none !important;
  outline: none !important;
  /* background: transparent !important; */
}

.search_box_info,
.PhoneInputCountrySelect {
  display: block;
}

/* .search_box_info, .PhoneInputCountry, .PhoneInputCountrySelect{
  display: block;
}
.search_box_info1,.PhoneInputCountry,.PhoneInputCountrySelect{
  display: none;
} */

.select.PhoneInputCountrySelect {
  display: none !important;
}

/* // north api  */
/* i18n css */
.language-selector {
  margin: 10px;
}

.language-select {
  padding: 4px;
  font-size: 16px;
  border: 0px !important;
  border-radius: 8px;
  background-color: transparent;
  color: #333;
  appearance: none;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-select {
  background-image: none !important;
}

.custom-form-select {
  background-image: none !important;
  background: #e5e3e8 !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  /* color: # !important; */
  padding: 6px 10px !important;
}

.language-select:focus {
  border-color: #612cbd;
}

.language-select option {
  padding: 10px;
}

/* Custom dropdown arrow */
.language-select::after {
  content: "\25BC";
  /* Unicode character for downward arrow */
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #555;
}

/* i18n css */

@media (max-width: 991px) {
  .profile_info {
    flex-wrap: wrap;
    flex: 0 0 100%;
  }

  .welcome_page_info {
    width: 100%;
    overflow: hidden !important;
  }

  .profile_info div {
    flex: 0 0 30% !important;
  }

  .tab_info {
    margin-top: 50px;
    flex: 0 0 100%;
  }

  .profile_info img {
    width: auto;
  }

  .profile_data {
    flex: 0 0 100%;
  }
}

@media (max-width: 479px) {
  button.starttour_btn {
    margin-bottom: 10px;
    padding: 6px 18px !important;
    font-size: 13px;
  }

  table.bg_cls_info.table th {
    padding: 8px 3px;
    font-size: 13px;
  }

  .table tr {
    text-align: center;
    font-size: 13px;
    white-space: nowrap;
  }

  .welcome_info {
    margin-bottom: 30px;
  }

  p.accunt_info_cls {
    margin-top: 10px !important;
  }

  .btn_info_cls {
    line-height: 12px;
    padding: 6px 10px;
    font-size: 10px;
  }

  .profile_info img {
    width: 100% !important;
  }

  .tab-pane form {
    padding: 0px;
    display: flex;
    justify-content: center;
  }

  .tab-pane .row {
    justify-content: center;
    padding-left: 0px !important;
    padding-right: 0px;
  }

  /* -------------------start mobile view------------------------- */
  .mobile_logo {
    display: block !important;
  }

  button.button-menu-mobile.open-left {
    display: none;
  }

  .navbar-custom div {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
  }

  .mobile_logo {
    order: 1;
  }

  ul.list-unstyled.topbar-menu.float-end.mb-0.d-flex.align-items-center {
    order: 2;
  }

  .mobile_logo img {
    /* width: 130px; */
  }

  .mobile_logo {
    margin-left: 15px;
  }

  /* --------------------end mobile view----------------------------------------- */
}

@media only screen and (min-width: 320px) and (max-width: 769px) {
  .signup_right {
    display: none !important;
  }

  .parentLeftSidebar {
    height: 100vh !important;
  }

  .welcome_page_info {
    height: none !important;
    /* background: #fff !important; */
  }

  .add_border_right {
    border-right: 0px !important;
  }

  .table_responsive {
    overflow-x: scroll !important;
    /* width: 100%; */
  }

  .toggle-icon.cp svg {
    display: none !important;
  }

  button#nav-home-tab {
    font-size: 8.26px !important;
    padding: 6px !important;
  }

  button#nav-profile-tab {
    font-size: 8.26px !important;
    padding: 6px !important;
  }

  button#nav-bank-tab {
    font-size: 8.26px !important;
    padding: 6px !important;
  }

  button#nav-home-tab {
    font-size: 8.26px !important;
    padding: 6px !important;
  }

  .add_color_heading {
    font-size: 24px !important;
    line-height: 26px !important;
    font-weight: 600;
    color: rgba(99, 46, 190, 1);
  }

  .tab_info {
    margin-top: 0px !important;
  }

  .settingsTabs {
    font-size: 8.26px !important;
  }

  [data-layout="financials"] .note,
  [data-layout="sheet"] .note {
    white-space: nowrap !important;
  }

  [data-layout="financials"] .ui.bizq.table tbody tr td:first-child,
  [data-layout="sheet"] .ui.bizq.table tbody tr td:first-child {
    white-space: nowrap !important;
  }

  li.side-nav-item {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .activeClassSidebar {
    width: auto !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    display: flex;
    align-items: center;
    justify-content: start;
  }

  @keyframes fadeDown {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .leftside-menu {
    text-align: center;
    animation: fadeDown 0.2s ease-in-out;
  }

  .side-nav .side-nav-link span {
    font-size: 12px;
  }

  .textLeftSidebar {
    font-size: 12px;
    color: #9d9d9d;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  [data-simplebar] {
    margin-bottom: 0px !important;
  }

  .side-nav {
    margin-bottom: 0% !important;
  }

  /* .lalalalal {
    overflow-y: auto; 
    max-height: 100vh; 
    min-height: 5vh !important;
  } */

  /* .leftside-menu {
    height: 40vh !important;
  } */
}

.add_border_right {
  border-right: 2px solid #eceff1;
}

.annim {
  color: red;
}

.leftside-menu {
  /* text-align: center; */
  animation: fadeDown 0.2s ease-in-out;
}

/* .side-nav .side-nav-link span {
  font-size: 0.9375rem;
} */
[data-simplebar] {
  margin-bottom: 0px !important;
}

.side-nav {
  margin-bottom: 0% !important;
}

.iconsLeftSidebar {
  color: #9d9d9d !important;
}

/* .textLeftSidebar {
  font-size: 0.9375rem;
} */
.hide_box select.PhoneInputCountrySelect {
  display: none;
}

.activeClassSidebar {
  font-weight: 700;
  /* width: 230px; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: #7487e6 !important;
  color: #fff !important;
}

.activeClassSidebar a.px-2.textLeftSidebar span.px-2.sidebar_listItems {
  color: #fff;
}

.side-nav-link {
  display: flex;
  align-items: center;
  justify-content: start;
  white-space: nowrap;
}

.activeClassSidebar span {
  /* color: white !important; */
}

.activeClassSidebar:hover {
  color: #000;
}

.logoutModal {
  width: 255px;
}

@media only screen and (min-width: 200px) and (max-width: 769px) {
  .conatiner-fluid {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}

.parentLeftSidebar {
  display: flex;
  background-color: #faf8ff;
  flex-direction: column;
  height: 90%;
  /* Ensure the parent container fills the height of its parent */
}

@media (min-width: 768px) {
  /* Adjust the breakpoint according to your mobile view */
  .media_mob {
    flex-grow: 1;
    /* Allow the first div to grow and take available space */
  }
}

.bottom-sidebar {
  align-self: flex-end;
  /* Align the second div to the bottom of the parent container */
}

.sidebar_listItems {
  font-family: Nunito;
  /* font-weight: 700; */
}

.settingsTabs {
  font-size: 16px;
}

.backgroundColorForTables {
  background-color: #f4f0ff;
  border-radius: 5px;
}

.backgroundColorForTableschange {
  background-color: rgb(243, 243, 243);
  border-radius: 5px;
}

.borderBottomColorForTables {
  border-bottom: 1px solid #e3d9ff;
}

.fontBoldForTable {
  font-weight: bold;
}

.heading_Dashboard {
  color: #632ebe;
  font-size: large;
  font-weight: 700;
}

.striped-table tbody tr:nth-child(even) {
  background-color: #f4f0ff;
  /* Set the background color for odd rows */
}

.striped-table {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.striped-table-header {
  background-color: #f4f0ff;
  /* Set the background color for odd rows */
}

.redHighlightTopbackground {
  background: #fef1f1;
  color: #ff0202;
  margin-left: 1px;
  margin-right: 1px;
}

.ccEFvh {
  background-color: #632ebe !important;
}

.kklZaP {
  background-color: #f0e8ff !important;
  color: #000 !important;
  font-size: 12px !important;
}

.dXrRVT {
  display: none !important;
}

.hzuvgG {
  margin-right: 30px !important;
}

.eGibQe {
  box-shadow: none !important;
  border: 1px solid #632ebe;
}

.hzNPjE {
  border-top: 1px solid #632ebe !important;
}

/* .btn_loader{
  display: flex;
  al
} */

.kaHhsh {
  height: calc(84vh - 112px) !important;
}

@media only screen and (min-width: 768px) and (max-width: 3100px) {
  .chatbot-container {
    position: fixed;
    bottom: 50px;
    top: 10vh;
    right: 15px;
    z-index: 999;
  }

  .bibEQi {
    width: 20vw !important;
    box-shadow: none !important;
    border: 1px solid #632ebe !important;
    height: 84vh !important;
  }
}

@media only screen and (min-width: 200px) and (max-width: 767px) {
  .chatbot-container {
    width: 100% !important;
  }

  .heading_subUser {
    /* color: #632ebe; */
    font-size: 15px !important;
  }

  .graph_info {
    overflow-x: scroll;
  }

  .table_responsive_data_sub_user {
    overflow-x: scroll !important;
  }
}

.activeCondensedClass {
  background-color: #7487e6 !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* .activeIconCondensed {
  background-color: wh !important;
  color: white !important;
} */
.seeMoreTextDashboard {
  color: rgb(70, 70, 239);
  cursor: pointer;
  font-weight: normal;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust as needed */
  right: 10px;
  /* Adjust as needed */
  transform: translateY(-50%);
  cursor: pointer;
}

.btn_inf_cls_cancel {
  padding: 6px 10px;
  font-size: 12px;
  line-height: 14px;
  padding: 0.4rem 0.8rem;
  transition: all 0.3s linear;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  border-radius: 5px;
}

.EartIcon {
  background-image: url("/src/assets/images/flags/globe.png") !important;
  background-size: 20px !important;
  /* Set the width */
  background-size: 20px !important;
  /* Set the width */
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* .graph_info table tbody tr:nth-child(even) {
  background: #f4f0ff;
} */

.graph_info table tr td {
  font-size: 15px;
  font-weight: 500;
  padding: 7px 15px !important;
  color: #534d4d;
}

.dropdowm {
  cursor: pointer;
  background: #faf8ff;
  border: 2px solid #d3d3d3;
  border-radius: 6px;
  overflow: hidden;
  width: 9.1rem !important;
}

.add_border_bottom {
  border-bottom: 2px solid #d3d3d3;
}

.add_border_bottom:hover {
  background-color: lightgray !important;
}

.dropdowm:not(:nth-child(3)) {
  border-bottom: 0px;
}

.add_position {
  width: 19rem;
  transform: translate(-25px, 56px);
}

.dropdownBg {
  background-color: lightgray !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.goog-te-gadget {
  color: #fff !important;
}

div#google_translate_element span {
  display: none;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none;
}

div#goog-gt-tt {
  display: none !important;
}

.skiptranslate.goog-te-gadget {
  position: relative;
}

select.goog-te-combo {
  border: 0;
  left: 0px;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
}

select.goog-te-combo:focus {
  outline: none;
}

li.multi_language {
  display: flex;
}

li.multi_language img {
  width: 23px;
}

.goog-te-gadget .goog-te-combo {
  margin: 0px 0px !important;
}

.mobile_logo {
  display: none;
}

.flag_info select.goog-te-combo {
  left: 0;
}

.flag_info img {
  width: 20px;
}

body.authentication-bg {
  top: 0 !important;
}

.text_info {
  padding-top: 15px;
  padding-right: 15px;
  text-align: right;
}

.text_info select {
  width: 56px;
}

.sf-footer {
  line-height: 0px;
  /* padding-bottom: 10px !important; */
  /* margin-bottom: 10px !important; */
}

.cp {
  cursor: pointer !important;
}

.cp::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

/* table.ui tbody tr:nth-child(1) {
  display: none;
} */

/* table.ui tbody tr:nth-child(3) {
  display: none;
}


table.ui tbody tr:nth-child(6) {
  display: none;
}

table.ui tbody tr:nth-child(7) {
  display: none;
}

table.ui tbody tr:nth-child(5) {
  display: none;
}

table.ui tbody tr:nth-child(8) {
  display: none;
} */

/* Mobile View */

.accordion-header {
  border: 0px;
  background-color: #632ebe;
  margin-bottom: 15px;
  color: white !important;
}

.accordion-item {
  border: 0px;
}

.accordion-button:not(.collapsed) {
  background-color: #632ebe !important;
}

/* .d3kit-chart-root {
  display: none !important;
} */

.mdi-chevron-down.accordio::before {
  font-size: 30px;
  content: "\f0140";
  color: white;
  position: absolute;
  /* / top: 0px;/  */
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: end;
}

.accordion-button:not(.collapsed)::after {
  display: none !important;
}

.accordion-button::after {
  display: none !important;
}

i.bi.bi-chat-dots.chat_pot {
  background: #632ebe;
  border-radius: 50px;
  width: 62px;
  height: 62px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
}

li.side-nav-item:hover {
  font-weight: 700;
  color: black !important;
  /* background-color: #f4f0ff!important; */
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.menuitem-active a:hover {
  color: #fff !important;
}

.active_link_mobile {
  background-color: #612bbd;
  color: white !important;
}

.bottom_portion {
  background: #632ebe;
  color: white;
  font-size: 25px;
}

.belgium_flgg {
  position: relative;
}

.belgium_flgg img {
  position: absolute;
  width: 23px;
  top: 50%;
  left: 7px;
  transform: translate(0, -50%);
}

.belgium_number {
  position: absolute;
  font-size: 13px;
  left: 40px;
  top: 50%;
  transform: translate(0px, -50%);
}

/* .belgium_flgg input {
  padding-left: 62px !important;
} */

.belgium_flgg input {
  line-height: 27px !important;
  height: 39px;
}

.add_custom_css {
  max-width: 400px;
  position: absolute;
  transform: translate(45px, 9px);
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

.custom-calendar {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-calendar .rbc-toolbar {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.custom-calendar .rbc-btn-group {
  margin-bottom: 10px;
}

.custom-calendar .rbc-btn {
  color: #333;
  background-color: transparent;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.custom-calendar .rbc-btn:hover {
  background-color: #eaeaea;
}

.custom-calendar .rbc-toolbar-label {
  font-size: 16px;
  font-weight: bold;
}

.custom-calendar .rbc-month-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.custom-calendar .rbc-row-content {
  border-top: 1px solid #ccc;
}

.custom-calendar .rbc-day-bg {
  background-color: #fff;
}

.custom-calendar .rbc-header {
  font-size: 12px;
  font-weight: bold;
  color: #666;
}

.custom-calendar .rbc-header + .rbc-header {
  border-left: 1px solid #ccc;
}

.custom-calendar .rbc-day-bg + .rbc-day-bg {
  border-top: 1px solid #ccc;
}

.custom-calendar .rbc-today {
  background-color: #ffffe0;
}

.custom-calendar .rbc-off-range {
  color: #ccc;
}

.react-calendar {
  width: 300px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border: 0px !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 35px;
  color: #612bbd;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 35px;
  color: #612bbd;
}

span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #632ebe;
  font-size: 16px;
  font-weight: 700;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 1em 0.5em !important;
}

.react-calendar__tile--hasActive {
  background: #f4f0ff !important;
  color: #632ebe !important;
  font-weight: 700 !important;
}

.react-calendar__tile--now {
  background: transparent !important;
}

button.react-calendar__tile.react-calendar__decade-view__years__year {
  color: #828282;
}

.topbarButtons {
  padding: 2px 5px !important;
}

.add_hover_effect:hover {
  background: #ddd7d7;
  padding: 3px;
  border-radius: 8px;
  color: #632ebe !important;
}

.react-calendar__navigation {
  border-bottom: 1px solid black !important;
}

.custonColor {
  color: #000000 !important;
}

.table_responsive th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 16.666%;
}

.table_responsive td {
  width: 16.666%;
  text-align: start !important;
}

.graph_info td {
  width: 33.333%;
  text-align: start !important;
}

.table_responsive_data_sub_user th {
  color: #612bbd !important;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-align: start !important;
  width: 14.2857%;
}

.table_responsive_data_sub_user td {
  width: 14.2857%;
  text-align: start !important;
}

a.px-2.textLeftSidebar span.px-2.sidebar_listItems {
  color: #8391a2;
}

.bg_info_cls_btn {
  background: #612cbd !important;
  border-radius: 10px !important;
  width: 129px !important;
  border: 0px !important;
}
.btn_info_cls:focus {
  box-shadow: none !important;
  outline: none;
}

.bg_info_cls_btn2 {
  background: #9d9d9d !important;
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: none !important;
  width: 129px !important;
  border: 0px !important;
}

.add_diff_width_color {
  background: #612cbd !important;
  border-radius: 10px !important;
  width: 100px !important;
  border: 0px !important;
}

.add_diff_width {
  width: 100px !important;
  background: #9d9d9d !important;
  border-radius: 10px !important;
  border: 0px !important;
  box-shadow: none !important;
  border: 0px !important;
}

.whitSpace {
  white-space: nowrap !important;
}

.add_heading_color {
  font-size: 36px;
  color: rgba(97, 43, 189, 1);
  line-height: 19px;
  font-weight: 700;
}

.add_box_shadow {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  overflow: hidden;
  border-radius: 10px;
}

.add_para_heading {
  font-size: 30px;
  line-height: 39px;
  font-weight: 600;
}

.add_color_perfect {
  color: rgba(97, 43, 189, 1);
}

.right_img {
  width: 320px;
  height: 250px;
  margin-top: -5rem;
}

@media (min-width: 780px) and (max-width: 1380px) {
  /* Your CSS rules here */

  .right_img {
    width: 242px !important;
    height: 220px !important;
    margin-top: -5rem;
  }
}

.add_head_font_text {
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(99, 46, 190, 1);
}

.add_icon_size_four {
  width: 35px;
  height: 35px;
}

.add_modal_heading {
  font-size: 23px;
  line-height: 21px;
  font-weight: 700;
  color: rgba(97, 43, 189, 1);
}

.add_padding_child .modal-content {
  padding: 2.5rem;
  border-radius: 33px;
  padding-bottom: 0px !important;
}

.add_font_modal_para {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: black !important;
}

.modal-header {
  border-bottom: 1px solid rgba(99, 46, 190, 1);
}

.add_height_select {
  height: 3rem !important;
}

.add_bg_color {
  background-color: transparent;
  color: black;
  border-bottom: 2px solid rgba(97, 43, 189, 1);
}

.inactive_tab {
  background-color: rgba(243, 243, 243, 1);
  color: gray;
}

.add_font_tab {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.add_person_width {
  width: 100px;
  height: 100px;
}

.add_box_shadow_flag:hover {
  border: 1.5px solid #612bbd !important;
}

.add_size_flag {
  width: 25px;
  height: 25px;
}

.add_head_font_text_para {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
}

.add_data_card_img {
  width: 35px;
  height: 26px;
}

.form_control_input {
  background-color: transparent !important;
}

.payment-method {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.payment-method:hover {
  background-color: #f0f0f0;
  border-color: #ccc;
}

.payment-method.active {
  /* background-color: #612bbd; */
  border-color: #612bbd;
}

.react-datepicker-wrapper {
  width: 100%;
}

/* InviteAccountant.css */
.transition-button {
  transition: background-color 0.3s ease;
}

.disabled-button {
  background-color: #ccc;
  cursor: not-allowed;
}

.enabled-button {
  background-color: #632ebe;
  cursor: pointer;
}

.enabled-button:hover {
  background-color: #4e22a3;
}

.bg-selected {
  background-color: #d3d3d3;
  /* Replace with your preferred color */
}

.icon-container {
  display: flex;
  align-items: center;
}

.icon-container .fs-5 {
  cursor: pointer;
  margin-left: 1rem;
  /* Adjust spacing between icons as needed */
}

/* signup css  */
.add_font_heading {
  font-size: 25px;
  line-height: 28px;
  color: white;
}

.add_second_font {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.add_font_para {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.full-height-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.add_color_heading {
  font-size: 30px;
  line-height: 29px;
  font-weight: 600;
  color: rgba(99, 46, 190, 1);
}

.add_color_para {
  color: rgba(0, 0, 0, 1);
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
}

img.icon_img {
  width: 25.6px;
  height: auto;
}

.add_bg_border {
  background-color: white !important;
  border: 0.8px solid #e9e0e0 !important;
  border-radius: 9.6px !important;
}

.add_border_radius {
  border-radius: 10px !important;
}

body.authentication-bg {
  top: 0px !important;
}

.logo-sm {
  background: #faf8ff !important;
}

.add_top_border_radius {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.add_margin_buttom_box {
  margin-bottom: 3px;
}

.text_capitalize {
  text-transform: capitalize !important;
}

.danger_success {
  font-size: 20px;
  width: 30px;
  height: 30px;
}

.table_data_search thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.table_data_search table {
  width: 100%;
  border-collapse: collapse;
}

.table_data_search tbody {
  display: block;
  max-height: 60vh;
  overflow-y: auto;
  height: 60vh;
  width: 100%;
  border-top: 0px !important;
}

.table_data_search thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /* border-bottom: 1.5px solid #4e22a3; */
}

.table_data_search thead tr th {
  border: 0px !important;
}

.table_data_search tbody::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.table_data_search td {
  color: #534d4d !important;
  font-weight: 500;
}

.table_data_search th {
  color: #4e22a3 !important;
  /* border-bottom: 1px solid #4e22a3!important; */
}

/*  */
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}

.custom-select-input {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px;
  /* Space for the dropdown arrow */
  background-color: #fff;
  border: 1px solid #ccc;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
}

.custom-select-input:focus {
  outline: none;
  border-color: #632ebe;
}

/* .custom-select-wrapper::after {
  content: "\25BC";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #333;
} */

/* styles.css */
.iban_input {
  width: 20rem;
}

.time-slot-dropdown {
  position: relative;
  width: 150px;
}

.dropdown-header {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #fff;
  text-align: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 400px;
  /* Fixed height for the dropdown menu */
  overflow-y: auto;
  /* Enable scrolling if the content exceeds the height */
  border: 1px solid #ccc;
  background: #fff;
  z-index: 1000;
  height: 400px;
}

.dropdown-item {
  padding: 8px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

/* dropdown  */
.dropdowndata {
  position: relative;
  display: inline-block;
}

.dropdowndata-button {
  color: black;
  padding: 7px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  width: 10rem;
  height: 39px;
  /* border-radius: 7px; */
  background: gainsboro;
  display: flex;
  align-items: center;
  justify-content: start;
}

.dropdowndata-button:focus {
  outline: none;
}

.dropdowndata-content {
  display: block;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 158px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  height: 14rem;
  overflow-y: scroll;
  border-radius: 7px;
  margin-top: 7px;
}

.dropdowndata-content::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.dropdowndata-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dropdowndata-content li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdowndata-content li:hover {
  background-color: #ddd;
}

.introjs-nextbutton,
.introjs-prevbutton,
.introjs-skipbutton {
  background-color: #7487e6 !important;
  color: white !important;
  text-shadow: none !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.introjs-skipbutton {
  border: 1px solid #bdbdbd;
  text-decoration: none;
  font-size: 14px !important;
  border-radius: 0.2em !important;
  margin: 0.5rem !important;
  width: 4rem !important;
  height: 3em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #007bff;
  /* Change this color as needed */
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.add_height_select_pay {
  height: 2.6rem;
}

/* .introjs-tooltip .introjs-skipbutton {
  display: none !important;
} */

/* For Webkit-based browsers (Chrome, Safari, etc.) */
.invisible-scroll {
  overflow-y: scroll;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.invisible-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* .introjs-prevbutton {
  display: none !important; 
} */

/* .introjs-tooltipbuttons {
  display: flex;
  justify-content: flex-end;
} */

.PhoneInputCountry {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 3rem;
  padding-left: 10px;
}

/* .PhoneInput {
  display: flex;
  align-items: center;
} */

/* .PhoneInputCountryIcon {
  width: calc(
    var(--PhoneInputCountryFlag-height) *
      var(--PhoneInputCountryFlag-aspectRatio)
  );
  height: var(--PhoneInputCountryFlag-height);
} */
select.PhoneInputCountrySelect {
  width: 81px !important;
  padding-left: 5px !important;
}

/* img.PhoneInputCountryIconImg {
  width: 100%;
} */

.PhoneInputCountrySelect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  /* width: 100%; */
  margin-right: var(--PhoneInputCountrySelect-marginRight);
}

.PhoneInputCountrySelectArrow {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  /* Set the color of the arrow */
}

.contentdata {
  height: 15rem;
  overflow-y: scroll;
}

.contentdata::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */
}

.add_custom_css_instry {
  top: 0px;
  left: 0px;
  right: 0px;
  transform: translate(-5rem, 1.5rem);
  width: 17rem;
  z-index: 9999;
}

.hoverEffect:hover {
  color: black;
}

.info-color:hover {
  font-weight: 700;
  color: black !important;
}

.textLeftSidebar span.px-2.sidebar_listItems:hover {
  color: black !important;
}

.activeBorderClas {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

.form-check-input[type="checkbox"] {
  border-color: #4e22a3 !important;
}

.graph_data thead tr th {
  width: 33.3%;
  text-align: start;
}

.font_size {
  color: #000000;
}

/* .graph_data thead, tbody tr{
  width: 300%!important;

} */
tr.active-on-hover {
  width: 300%;
}

.graph_data tr:nth-child(even) {
  background-color: #f4f0ff;
  /* Light gray for even rows */
}

.graph_data tr:nth-child(odd) {
  background-color: #ffffff;
  /* White for odd rows */
}

.graph_info table tr th {
  font-size: 15px;
  text-align: start;
  color: #4e22a3;
  /* border-bottom: 2px solid #4e22ae!important; */
}
.noOutlineInput:focus {
  outline: none !important;
}
.scroll-container {
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
.no_outline_input:focus {
  outline: none !important;
}

.cc-email-input {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cc-input-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 4px;
  background-color: #e5e3e8;
  padding: 4px;
  min-height: 40px;
  gap: 4px;
}

.cc-tag {
  background-color: #4d22a3d5;
  padding: 4px 10px;
  font-size: 14px;
}

.cc-tag-close {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.cc-input {
  border: none;
  flex: 1;
  /* font-size: 14px; */
  outline: none;
  background-color: #e5e3e8;
  padding: 4px;
}

.cc-error {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
/* Hide arrows only for elements with the .no-arrows class */
.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield; /* Firefox */
}
.notranslate {
  unicode-bidi: isolate !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover {
  background-color: #4d22a3bb !important;
  color: white !important;
}
.react-datepicker__day--selected {
  background-color: #4e22a3 !important;
  color: white !important;
}
/* .modal-backdrop {
  background-color: #313a460e !important;
} */

.dropdown-toggle::after {
  display: none !important;
}

/* .custom-button-left::after {
  content: "";
  position: absolute;
  top: 0;
  right: -1rem;
  width: 15px;
  height: 98%;
  background-color: white;
  transform: skewX(-20deg);
} */

/* .custom-button-right {
  padding: 10px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  border: none;
  transform: skewX(-20deg);
} */

/* .custom-button-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1rem;
  width: 15px;
  height: 98%;
  background-color: white;
  transform: skewX(-20deg);
} */
/* .custom-button-left {
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  border: none;
} */

/* .custom-button-left::after {
  content: "";
  position: absolute;
  top: 0;
  right: -0.3rem;
  width: 15px;
  height: 98%;
  background-color: white;
  transform: skewX(-20deg);
} */
/* 
.custom-button-right {
  color: #000;
  padding: 10px 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  border: none;
} */

/* .custom-button-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: -0.4rem;
  width: 15px;
  height: 98%;
  background-color: white;
  transform: skewX(-20deg);
} */

.custom-button-left {
  background: linear-gradient(to right, #222, #111);
  color: white;
  border: none;
  cursor: pointer;
  width: 6rem !important;
  clip-path: polygon(0% 0%, 99% 0%, 88% 100%, 0% 100%);
  transition: 0.3s ease-in-out;
  margin-right: -2%;
}
.custom-button-right {
  background: linear-gradient(to right, #f8d775, #d6a733);
  color: black;
  border: none;
  width: 6rem !important;
  cursor: pointer;
  clip-path: polygon(11% 0%, 100% 0%, 100% 100%, 0% 100%);
  transition: 0.3s ease-in-out;
}
.form-select-customer {
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%238b96a0%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e) !important;
}
@media screen and (min-width: 1280px) and (max-width: 1620px) {
  .custom-button-left {
    /* border: 10px solid red !important; */
    width: 4.5rem !important;
    font-size: 11px !important;
  }

  .custom-button-right {
    /* border: 10px solid red !important; */
    width: 4.5rem !important;
    font-size: 11px !important;
  }
}
@media screen and (min-width: 1080px) and (max-width: 1279px) {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-size: 14px !important;
  }
  p,
  span,
  div,
  label,
  input {
    font-size: 12px !important;
  }
  .status_btns {
    font-size: 10px !important;
    padding: 0.3rem 0.5rem !important;
  }

  .custom-button-left {
    /* border: 10px solid red !important; */
    width: 3.5rem !important;
    font-size: 10px !important;
    padding-left: 10px !important;
  }
  .custom-button-right {
    /* border: 10px solid red !important; */
    width: 3.5rem !important;
    font-size: 10px !important;
  }
}

.status_btns {
  line-height: 14px !important;
  padding: 0.5rem 0.8rem !important;
  transition: all 0.3s linear !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: black;
  border: 1px solid #7487e6;
  border-radius: 5px;
  background-color: white;
  margin: 0 -3px;
}
.bgform {
  background-color: white !important;
  border: 1px solid #dee2e6 !important;
  color: #6c757d !important;
}

/* .trapezoid {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
} */
.first-button {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  /* border: 2px solid black; */
}
.middle-button {
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  /* border: 2px solid black; */
}
.last-button {
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  /* border: 2px solid black; */
}
.label-signin {
  font-size: 1.12rem;
}

.signin-inputs {
  height: 3rem !important;
  background-color: #e5e3e85f !important;
}
form .signin-inputs::placeholder {
  font-size: 1rem !important;
}
/* .languageSelectorText {
  font-size: 10.125rem !important;
} */
